import * as React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import SwiperCore, { Autoplay, Pagination, EffectFade } from 'swiper';

SwiperCore.use([Autoplay, Pagination, EffectFade]);

interface ISwiperProps {
  images: IContentfulImage[];
}

const CustomSwiper = (props: ISwiperProps) => {
  return (
    <>
      <Swiper
        effect={'fade'}
        // pagination={true}
        pagination={{
          el: '.swiper-pagination-detached',
          type: 'bullets',
        }}
        className="cloppus_swiper"
        slidesPerView={1}
        // onSlideChange={() => {console.log('slide change')}}
        // onSwiper={(swiper) => console.log(swiper)}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {props.images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={image.file.url} alt="" />
            </SwiperSlide>
          );
        })}
        ...
      </Swiper>
      <div className="swiper-pagination-detached"></div>
    </>
  );
};

export default CustomSwiper;
