import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';

import { Link } from 'gatsby';

const ProductRow = (props: IProductCardProps) => {
  const image = getImage(props.coverImg);
  let categorySlug = props.category?.slug ?? '';
  let productUrl = '/' + categorySlug + '/' + props.slug;

  return (
    <div className="row mb-5 pb-3">
      <div className="col-12 col-md-8">
        {image && (
          <Link to={productUrl}>
            <GatsbyImage image={image} objectFit="cover" alt={props.name} />
          </Link>
        )}
      </div>
      <div className="col-12 col-md-4 ps-md-3 pt-3 pt-md-0">
        <div className="row pb-3">
          <div className="col-12">
            <h2 className="p-0">
              <Link to={productUrl}>{props.name}</Link>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {documentToReactComponents(props.excerpt)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductRow;
