import * as React from 'react';
import Layout from '../components/layout';
import CustomSwiper from '../components/swiper/swiper';
import ProductRow from '../components/productRow/productRow';

import { StaticQuery, graphql } from 'gatsby';

// markup
const IndexPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query HomeData {
          gallery: contentfulGallery(slug: { eq: "home-gallery" }) {
            images {
              gatsbyImageData
              file {
                url
                fileName
              }
            }
          }
          products: allContentfulProduct(filter: { highlight: { eq: true } }) {
            nodes {
              name
              slug
              excerpt {
                raw
              }
              category {
                slug
                name
              }
              coverImage {
                file {
                  url
                  fileName
                }
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                  width: 800
                  height: 500
                )
              }
            }
          }
        }
      `}
      render={(data) => {
        console.log(data);
        let images = data.gallery.images;
        let products = data.products.nodes;

        return (
          <Layout>
            <CustomSwiper images={images} />
            <div className="container">
              <div className="row mt-5">
                <div className="col-12">
                  <h1 className="pt-2 pb-4">Selected Works</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {products.map(
                    (product: IContentfulProduct, index: number) => (
                      <ProductRow
                        key={product.slug + index}
                        slug={product.slug}
                        name={product.name}
                        excerpt={
                          product.excerpt ? JSON.parse(product.excerpt.raw) : ''
                        }
                        coverImg={product.coverImage}
                        category={product.category}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </Layout>
        );
      }}
    />
  );
};

export default IndexPage;
